<template>
    <div class="page">
        <div class="title">
            <div class="name">故障监测</div>
            <div class="search">
                <Select v-model="formItem.villageId" placeholder="全部小区" clearable style="width:120px">
                    <Option v-for="(item, index) in villageFormItemSelectList" :key="index" :value="item.v_village_id">
                        {{ item.v_village_name }}</Option>
                </Select>
                <Select v-model="formItem.vElevatorCode" placeholder="全部电梯" clearable style="width:120px">
                    <Option v-for="(item, index) in elevatorSelectList" :key="index" :value="item.v_elevator_code">
                        {{ item.v_elevator_name }}</Option>
                </Select>
                <Select v-model="formItem.iFaultType" placeholder="故障类型" clearable style="width:120px">
                    <Option v-for="(item, index) in iFaultTypeList" :key="index" :value="item.name">
                        {{ item.label }}</Option>
                </Select>
                <!-- <Select v-model="formItem.iStatus" placeholder="工单状态" clearable style="width:120px">
                    <Option v-for="(item, index) in iStatusList" :key="index" :value="item.name">
                        {{ item.label }}</Option>
                </Select> -->
                <DatePicker :value="formItem.dtReportTime" format="yyyy-MM-dd" type="date" style="width:120px" placeholder="开始时间" @on-change="formItem.dtReportTime = $event" />
                <DatePicker :value="formItem.dtEndTime" format="yyyy-MM-dd" type="date" style="width:120px" placeholder="结束时间" @on-change="onChangeEndTime" />
                <Button type="primary" @click="searchList()">查询</Button>
                <Button style="margin-left: 10px;" type="primary" @click="exportList()">导出</Button>
            </div>
        </div>
        <div class="content">
            <tblcomponents ref="tblcomponents" :formItem="formItem" :tabelConfigVal="tabelConfig" :tabelDataVal="tabelData" :tabelLoadingVal="tabelLoading" :totalRecordCntVal="totalRecordCnt" @search="searchList" data-index="vDeptId" />
        </div>
        <MXFaultModal ref="faultModalRef" />
    </div>
</template>
<script>
import {
    queryFaultList,
    getFaultDetail,
    getFaultDefinitionSearch,
    generateRescuePdf,
    generateRepairPdf,
    getElevatorFaultRealTimeByEvent,
    exportExcel,
} from '@/api/repair/index.js'

import {
    getElevatorsStatus,
    searchVillageSelectList
} from '@/api/home/index.js'
import { Message } from 'view-design'
import * as ElevatorApi from '@/api/elevator/index'
import MXFaultModal from '@/pages/components/mx-biz-components/mx-fault-modal'

export default {
    data() {
        return {
            realData: [],
            modaDetail: false,
            iFaultTypeList: [{
                    name: 'BYLX01',
                    label: '半月保'
                },
                {
                    name: 'BYLX02',
                    label: '季度保'
                },
                {
                    name: 'BYLX03',
                    label: '半年保'
                },
                {
                    name: 'BYLX04',
                    label: '年度保'
                }
            ],
            iStatusList: [{
                    name: 1,
                    label: '渠道上报'
                },
                {
                    name: 2,
                    label: '新建工单'
                },
                {
                    name: 3,
                    label: '已接单'
                },
                {
                    name: 4,
                    label: '已签到'
                },
                {
                    name: 5,
                    label: '已完成'
                },
                {
                    name: 6,
                    label: '已确认'
                },
                {
                    name: 7,
                    label: '误报'
                }
            ],

            elevatorFormItemProjectSelectList: [],
            villageFormItemSelectList: [],
            faultImageList: '',
            model1: '',
            faultInfoDetail: {},
            videoUrl: '',
            searchLoading: false,
            open: false,
            tabelConfig: this.tableTool.setColumn([{
                    title: '电梯名称',
                    key: 'v_elevator_name',
                    type: 'elevatorName'
                },

                {
                    title: '所属小区',
                    key: 'villageName',
                    type: 'villageName'
                },

                {
                    title: '地址',
                    key: 'v_address',
                    type: 'address'
                },
                {
                    title: '故障类型',
                    key: 'v_fault_name',
                    type: 'faultName'
                },
                {
                    title: '时间',
                    key: 'dt_report_time',
                    type: 'datetime',
                    render: (h, params) => {
                        return h('div', [
                            h('font', this.formatDateVal(params.row.dt_report_time))
                        ])
                    }
                },
                {
                    title: '操作',
                    key: 'action',
                    type: 'operate',
                    align: 'center',
                    render: (h, params) => {
                        const btns = []
                        btns.push(
                            h(
                                'Button', {
                                    props: {
                                        type: 'primary',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.showDetail(params.row.v_fault_id, params.row.eventId)
                                        }
                                    }
                                },
                                '查看'
                            )
                        )
                        return h('div', btns)
                    }
                }
            ]),
            tabelData: [{
                    v_data: '123'
                },
                {
                    v_data: '123'
                },
                {
                    v_data: '123'
                },
                {
                    v_data: '123'
                }
            ],
            tabelLoading: false,
            totalRecordCnt: 0,
            formItem: {
                villageId: '',
                buildingId: '',
                vElevatorCode: '',
                dtEndTime: this.formatDate(0),
                dtReportTime: this.formatDate(-30),
                iFaultType: '',
                overdue: '',
                pageIndex: 1,
                pageSize: 10,
                vProjectId: '',
                iUncivilizedBehaviorFlag: 0
            },
            faultInfoDetail: '',
            videoUrl: '',
            faultImageList: '',
            elevatorSelectList: [],
            elevatorData: { vVillageId: '', vProjectId: '' }
        }
    },
    components: {
        MXFaultModal
    },
    created() {
        // 获取物业部门
        this.searchList()

        searchVillageSelectList(this.buildParams({})).then(res => {
            this.villageFormItemSelectList = res.info
        })
        getFaultDefinitionSearch(1, 1).then(res => {
            const arr = []
            res.info.forEach(function(item, index) {
                arr.push({
                    label: item.faultName,
                    id: index + 2,
                    name: item.faultType
                })
            })
            this.iFaultTypeList = arr
        })
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    methods: {
        searchList() {
            this.searchLoading = true
            this.tabelLoading = true
            let params = this.formatTimeParams(
                'dtReportTime',
                'dtEndTime',
                this.formItem
            )
            queryFaultList(this.buildParams(params)).then(res => {
                this.tabelData = res.info.list
                this.$refs['tblcomponents'].pageIndex = res.info.pageIndex
                this.$refs['tblcomponents'].pageSize = res.info.pageSize
                this.totalRecordCnt = res.info.totalRecordCnt
                this.tabelLoading = false
                this.searchLoading = false
            })
        },
        exportList() {
            let params = this.formatTimeParams(
                'dtReportTime',
                'dtEndTime',
                this.formItem
            )
			params.pageSize = this.totalRecordCnt
            exportExcel(this.buildParams(params)).then(res => {
              
                this.$download.excel(res.data, '故障监测数据.xls');
            })
        },
        showDetail(faultId, eventId) {
            this.$refs.faultModalRef.show(faultId, eventId)
        },
        getOrderPdf(faultType, number) {
            if (faultType == '09') {
                generateRescuePdf({
                    eventNumber: number
                }).then(res => {
                    if (res.info && res.info.fileUrl) {
                        window.open(res.info.fileUrl, '_blank')
                    }
                })
            } else {
                generateRepairPdf({
                    eventNumber: number
                }).then(res => {
                    if (res.info && res.info.fileUrl) {
                        window.open(res.info.fileUrl, '_blank')
                    }
                })
            }
        },
        buildParams(params) {
            params.vProjectId = this.user.user.v_project_id
            return params
        },
        onChangeEndTime(e) {
            this.formItem.dtEndTime = e
        },
        eveType(type) {
            if (type == 1) {
                return "直梯"

            } else {
                return "扶梯"
            }
        },
        formatTime(startKey, endKey, form) {
            let params = JSON.parse(JSON.stringify(form))
            let data = this.validateAndFormatTime(params[startKey], params[endKey])
            if (data.msg.length) {
                Message.error({
                    content: data.msg,
                    duration: 5
                })
                delete params[startKey]
                delete params[endKey]
                delete form[startKey]
                delete form[endKey]
            } else {
                params[startKey] = data.startTime
                params[endKey] = data.endTime
            }
            return params
        }
    },
    watch: {
        'formItem.villageId': function(newObj, OldObj) {
            if (newObj) {
                this.elevatorData.vVillageId = newObj
                ElevatorApi.searchElevatorNotPage(this.elevatorData).then(res => {
                    this.elevatorSelectList = res.info
                })
            } else {
                this.formItem.vElevatorCode = ''
                this.elevatorSelectList = []
            }
        }
    }
}
</script>
<style lang="less" scoped>
/deep/.ivu-modal-content {
    background-color: #f2f7fb;
}

/deep/.ivu-modal-header p,
/deep/.ivu-modal-header-inner {
    font-weight: 700;
    padding-left: 20px;
}

/deep/.ivu-modal-header {
    border: none;
}

/deep/.ivu-modal-body {
    padding-top: 0;
}

.ivu-select,
.ivu-date-picker {
    margin-right: 10px;
}

.title {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.name {
    font-size: 18px;
    font-weight: 700;
    padding-left: 20px;
    color: #fff;
}

.page {
    padding: 0 10px 0;
}

.content {
    padding: 10px;
}

.flexVideo {
    video {
        width: 100%;
    }
}

.TimeDiv::-webkit-scrollbar {
    display: none;
}

.ivu-col-offset-7 {
    margin-left: 37%;
}

/deep/ .ivu-tabs .ivu-tabs-tabpane {
    height: 400px;
}

.img-container {
    img:not(:first-child) {
        margin-top: 32px;
    }
}

.flexVideo video[data-v-009b1358] {
    height: 400px;
}

.live-progress {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 18px;

    h2 {
        margin: 16px;
    }

    &>.item {
        flex: 1;
        position: relative;
        margin-bottom: 15px;

        .box {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 50px;
            border: 1px solid rgb(134, 131, 131);
            border-radius: 25px;
            align-items: center;
            justify-content: center;

            &>div {
                width: 100%;
            }
        }

        .title {
            font-size: 18px;
            color: #515a6e;
        }

        .text {
            display: flex;
            width: 100%;
            padding: 0 10px;
            margin-top: 4px;
            font-size: 14px;
            color: #515a6e;
            justify-content: space-around;
        }

        &.active {
            background-image: linear-gradient(to right, #00ffff, #00aff8);

            .title,
            .text {
                color: #0d2f6a;
            }
        }

        &:after {
            position: absolute;
            @include center(x);
            width: 1px;
            background-color: #000;
            content: '';
            left: 50%;
            top: 50px;
            height: 15px;
        }

        &:last-child:after {
            background-color: transparent;
        }
    }
}
</style>